import React from 'react';

const About = () => {
  return (
    <div id="about-me" class="about-me">
      <h2 class="name">
        <span class="long-dash"></span>
        <span class="name-text">About me</span>
      </h2>
      <p>
      I am a software engineer interested in developing ideas into code. Equipped with React, HTML, CSS, and JavaScript on the front-end and Ruby on Rails, Elasticsearch, and PostgreSQL on the back-end, I develop web applications that have intuitive user experiences with feature-rich functionality.
      </p>
      <p>
      Take a look at my portfolio for some projects I like the most. Let's connect if you want to collaborate on something awesome; always looking forward to new challenges, putting my skills to the test!
      </p>
    </div>
  )
}

export default About;